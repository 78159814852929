<template>
  <v-container fluid fill-height class="p-5">
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 class="px-5">
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-btn
              class="btn-block"
              @click="goToFarmasiLayar('farmasi_sentral')"
              large
              color="primary"
              >FARMASI CENTRAL</v-btn
            >
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-btn
              class="btn-block"
              @click="goToFarmasiLayar('farmasi_edelweis')"
              large
              color="purple"
              dark
              >FARMASI EDELWIS</v-btn
            >
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-btn
              class="btn-block"
              @click="goToFarmasiLayar('farmasi_anggrek')"
              dark
              large
              color="red darken-1"
              >FARMASI ANGGREK</v-btn
            >
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
export default {
  name: 'FARMASIDISPLAY',
  data () {
    return {}
  },
  methods: {
    goToFarmasiLayar (PARAMS) {
      this.$router.push({
        name: 'LAYARFARMASI',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          loc_id: PARAMS
        }
      })
    }
  }
}
</script>
